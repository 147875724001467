import { Builder_, Dates_, Slider, Slider_ } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const Dailyprofit_Subscriber_Filters = (props) => {
    const {t} = useTranslation();

    const onChange = (selections) => {
        // props.onDates(selections)
        const startDate = selections[0];
        const endDate = selections[1];
        props.setDateFilters({
            startDate,
            endDate,
            renderedDates: selections,
        });

        if (startDate && endDate) {
            const payload = { startDate, endDate };
            props.fetchSubscriberInfo(payload);
        }
    };

  return (
    <Slider_
        title={t("")} 
        //text={t("I wish to continue")} 
        //  icon="Next" 
        onClick={()=>{props.toggleFilters(false); props?.refetchKPIs();} }
        grey={t("Cancel")}
        icon="Check"
        iconGreyLeft="Xicon"
        onClose={()=>props.toggleFilters(false)}
        onGrey={()=>props.toggleFilters(false)}
        text={t("Done")}
    >
        <Builder_ title={t("Show only specific period")}>
            <Dates_ 
                allowPartialRange={true}
                onChange={onChange} 
                value={props?.dateFilters?.renderedDates} 
                selectRange={true} 
                dateFullCellRender={(date) => date?.date()} 
                maxDate={new Date()}
            />
        </Builder_>
    </Slider_>
  )
}
