import { Card, CardsForty, CardsSixty, device, Grey_Link, IconSvg, logo, Main_, Main_Plain, Main_Text_Loader, On_Click_Card, PageAlwaysVisible, PageForty, PageFull, PageSixty, PageSubtitle, PageTitle, ThemeContext } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get_subscriber_info } from '../../../services/api/Mocks';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Graph_Box from '../../partials/graphBox/Graph_Box';
import { parseISO, differenceInMonths, addMonths, format, compareAsc, differenceInDays } from "date-fns";
import { Dailyprofit_Subscriber_Filters } from './partials/Dailyprofit_Subscriber_Filters';
import { get_meta_adspend, get_meta_adspend_from_questad, get_total_revenue } from '../../../services/api/Subscriptions';
import { calculateLongestStreak, calculateMonths, calculateSum, fetchAndSetQuestAdSpends, matchRevenuesToSubs } from './utils';
import { Dailyprofit_Subscriber_Invoices } from './partials/Dailyprofit_Subscriber_Invoices';
import Dailyprofit_Subscriber_Fix_Quest from './partials/Dailyprofit_Subscriber_Fix_Quest';

const DailyprofitSubscriber = styled.div`
  
`

const SubscriberFixActions = styled.div`
  padding: 1rem 2rem;

`
const SubscriberCardWrapper = styled.div` 
  padding-top: 1rem;
  h3{
    padding-bottom: 1rem;
  }
  
  `

const SubscriberInfo = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  gap:1rem; 

  @media ${device.sm} {
   
    display: block;
    padding-left: 1rem;
    padding-bottom: 2rem;
  }

`

const SubscriberInfoInner = styled.div`
  display: flex;
  align-items: center;
  gap:1rem; 

  img{
    width: 3.5rem;
    height: 3.5rem;
    border-radius: var(--smallRadius);
  }

  @media ${device.sm} {
    align-items: left;
  }
`
const SubscriberInfoUser = styled.div`
  border-radius: var(--smallRadius);
  background: ${p=>p.theme.mid};
  padding: var(--smallRadius);
`

const SubscriberCard = styled(Card)`
  cursor: pointer;
  background: ${p => p.theme.low};
    ;
  /* box-shadow: none; */
  padding: 1rem 2rem;
  margin-bottom: 2rem;
`
const SubscriberCardInner = styled.div`
  width: 100%;
`

const SubscriptionCardInfo = styled.div`
  display: flex;
  align-items: baseline;
  gap: 2rem;
  justify-content: space-between;

  @media ${device.lg} {
    flex-direction: column;
  }

  @media ${device.md} {
    flex-direction: row;
  }

  @media ${device.sm} {
    flex-direction: column;
  }
`

const SubscriptionCardLeftInner =  styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media ${device.sm} {
    grid-template-columns: 1fr;
    gap: 0rem;
  }
`
const SubscriptionCardGrid = styled.div`

  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: .5rem;

  padding: 1rem 2rem;

  @media ${device.lg} {
    grid-template-columns: 1fr;
  }

  @media ${device.md} {
    grid-template-columns: auto 1fr;
  }

  @media ${device.sm} {
    grid-template-columns: 1fr;
  }
`

const SubscriptionCardItem = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.5rem;

  svg{
    width: 1rem;
    fill:  ${p => p.status === 'active' ? `var(--success)` : 'var(--error)'} !important;
  }
`

const SubscriptionCardLeft = styled.div`
    width: 100%;
  `

const PageTitleInner= styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`
export default function DAILYPROFIT_SUBSCRIBER() {
    const {t} = useTranslation();
    const params = useParams();
    

    // console.log('userId', userId);
    const {theme} = useContext(ThemeContext);
    const navigate = useNavigate();
    const [mergedQuests, setMergedQuests] = useState();

    const [dateFilters, setDateFilters] = useState({startDate:'', endDate:''})

    const [filters, toggleFilters] = useState(false)
    const [kpis, setKPIs] = useState({changed: false})

    const [questAdSpends, setQuestAdSpends] = useState([]);

    const [selectedQuest, setSelectedQuest] = useState(false)

    const [subscriber, setSubscriber] = useState('')
    const [quests, setQuests] = useState([])

    const [fixQuest, setFixQuest] = useState(false)
    const [questToFix, setQuestToFix] = useState(false)

    
    const fetchSubscriberInfo = (payload = {}) => {
      const { startDate, endDate } = payload;
  
      get_subscriber_info({ userId: params?.id, startDate, endDate })
          .then((res) => {
              console.log("RESSS", res);
              if (res?.data?.subs?.length) {
                setQuests(res?.data?.subs?.filter(sub=> sub?.category === 'dailyprofit'))
              }
              setSubscriber({ ...subscriber, subs: res?.data?.subs, info: res?.data?.user });
          })
          .catch((err) => {
              console.error("Error fetching subscriber info:", err);
          });
      
  };


  const hasDailyprofitSub = subscriber?.subs?.some(sub=> sub?.category === 'dailyprofit');
  const hasLoisSub = subscriber?.subs?.some(sub=> sub?.category === 'lois');
  //const quests = subscriber?.subs?.filter(sub=> sub?.category === 'dailyprofit');

  const getKPIs = async() => {

    let customer = quests[0]?.cusId;
    let subIds = [];

    let timeRanges = false;
    let timeRangesMeta = false;

    if (dateFilters?.startDate && dateFilters?.endDate) {
        timeRanges = {from: dateFilters?.startDate?.toISOString().split('T')[0], until: dateFilters?.endDate?.toISOString().split('T')[0]};
        timeRangesMeta = {since: dateFilters?.startDate?.toISOString().split('T')[0], until: dateFilters?.endDate?.toISOString().split('T')[0]};
    }

    console.error('AA A aaaa', quests)
    
    let revenue = await get_total_revenue(customer, timeRanges, false, true);

    if (revenue?.kpis?.length) {
      subIds = revenue?.kpis?.map(sub => sub?.questId);
    }

    // let metaAdspend = await get_meta_adspend_from_questad(subIds, false, true);
    let metaAdspend = [];

    console.error(revenue)

    setKPIs({totalRevenue: revenue?.totalRevenue, 
            metaAdspend: metaAdspend,
            totalFee: revenue?.totalFee,
            totalMaxAdspend: revenue?.totalExpectedAdSpend,
            kpis: revenue?.kpis,
            changed: !kpis?.changed

  })
  }


  const calculateMRR = (subscriptions) => {
    if (!subscriptions || subscriptions.length === 0) return 0;

    // Filter active subscriptions
    const activeSubscriptions = subscriptions.filter(sub => sub.status === "active");
    console.log('activeSubscriptions', activeSubscriptions);

    // Sum the prices of active subscriptions, using newBudget if defined, otherwise amountPaid
    const totalMRR = activeSubscriptions.reduce((sum, sub) => {
        const valueToAdd = sub.newBudget !== undefined ? sub.newBudget : sub.amountPaid;
        return sum + valueToAdd;
    }, 0);

    return totalMRR;
};

  useEffect(() => {
    fetchSubscriberInfo();
  }, []);

  useEffect(() => {
     if (subscriber?.subs?.filter(sub=> sub?.category === 'dailyprofit')?.length) {
      getKPIs();
    }
  }, [subscriber]);


    useEffect(() => {
      if (kpis?.kpis?.length) {

        let newQuests = matchRevenuesToSubs(quests, kpis);

        fetchAndSetQuestAdSpends(quests, newQuests, setQuestAdSpends, setMergedQuests, dateFilters);

      }
    }, [kpis?.changed]);


    console.log('questadspends', questAdSpends);
    console.log('mergedQuests', mergedQuests);
    
  if(!mergedQuests?.length) return <Main_Text_Loader text='Loading Quests' />
  return (
    <DailyprofitSubscriber>
    {filters ? 
      <Dailyprofit_Subscriber_Filters 
        dateFilters={dateFilters}
        setDateFilters={setDateFilters}
        toggleFilters={toggleFilters}
        fetchSubscriberInfo={(payload)=>fetchSubscriberInfo(payload)}
        refetchKPIs = {getKPIs}
      />  
    : null}
    {selectedQuest?
      <Dailyprofit_Subscriber_Invoices
        selectedQuest={selectedQuest}
        setSelectedQuest={setSelectedQuest}
        userId={params?.id}
        dateFilters={dateFilters}
      />
  
    : null}
    {fixQuest?
        <Dailyprofit_Subscriber_Fix_Quest
          fixQuest={fixQuest}
          setFixQuest={setFixQuest} 

          fetchSubscriberInfo={()=> fetchSubscriberInfo()}

          selectedQuest={questToFix} 
          setSelectedQuest={setQuestToFix}
        />
      : null}
      <PageFull>
        <PageTitle cta>
          <PageTitleInner>
            <Grey_Link iconLeft='Back' noPadding onClick={()=>navigate(-1)}/>
            <h1>{subscriber?.info?.firstname} {subscriber?.info?.lastname}</h1>
          </PageTitleInner>
          <Main_ text='Filters' iconLeft='Filter' onClick={()=>toggleFilters(true)}/>
        </PageTitle>
    </PageFull>
    <PageSixty>
      <PageAlwaysVisible>
      <SubscriberInfo>
        <SubscriberInfoInner>
          <img src={logo.dailyprofit[theme]} title='Questad'/>
          <SubscriberInfoUser>
            <h6>{subscriber?.info?.username} </h6>
          </SubscriberInfoUser>
        </SubscriberInfoInner>
          {/* <p>Started Questad: {new Date(oldestSubscription.current_period_start).toLocaleDateString('el-GR')} </p> */}
      </SubscriberInfo>
    
        <SubscriberCardWrapper>
        {mergedQuests?.map((subscription, index) => {
         const questAdSpend = questAdSpends[index]
         
        return(
          <>
          
          <SubscriberCard  >
            <SubscriberCardInner  onClick={()=> setSelectedQuest(subscription)} key={subscription._id} >
                <SubscriptionCardLeftInner>
                  <Graph_Box
                    kpiTitle={'Revenue'}
                    kpiValue={`€${subscription.amountPaid?.toFixed(2) || 0}`}
                    background={"low"}
                    color={'main'}
                    // unclickable
                  />
                  <Graph_Box
                    kpiTitle={'Adspend'}
                    kpiValue={`€${(subscription?.expectedAdSpend ? subscription.expectedAdSpend.toFixed(2) : "0.00")}`}
                    background={"mid"}
                    color={'focus'}
                    // unclickable
                  />
                  <Graph_Box
                    kpiTitle={'FB Adspend'}
                    kpiValue={`€${questAdSpend?.metaAdspend?.toFixed(2) || 0}`}
                    background={"mid"}
                    color={'facebookOne'}
                    // unclickable
                  /> 
                  <Graph_Box
                      kpiTitle={'Difference'}
                      kpiValue={`€${
                        subscription?.expectedAdSpend != null && questAdSpend?.metaAdspend != null
                          ? (subscription?.expectedAdSpend - questAdSpend?.metaAdspend).toFixed(2)
                          : "0.00"
                      }`}
                      // color={'error'}
                      color={subscription?.expectedAdSpend != null && questAdSpend?.metaAdspend != null
                        ? (subscription?.expectedAdSpend - questAdSpend?.metaAdspend).toFixed(2) >=0 ?  'success' : 'error'
                        : "0.00"}
                      background={"mid"}
                      // unclickable
                    />             
                </SubscriptionCardLeftInner>

                  <SubscriptionCardGrid>
                    <p>{t("Payment interval")}:</p>
                    <h5>{subscription?.interval == 'day' ? '30 days': subscription?.interval}</h5>
                    <p>{t("Subscription Status")}:</p>
                    <SubscriptionCardItem status={subscription.status}>
                      <IconSvg Icon='Bullet' />
                      <h5>{subscription.status}</h5>
                    </SubscriptionCardItem>
                    {subscription.status === 'canceled' ? 
                    <>
                    <p>{t("Total running time")}:</p>
                    <h5>
                      {new Date(subscription.createdAt).toLocaleString('el-GR')} - {new Date(subscription.current_period_end).toLocaleString('el-GR')}
                    </h5>
                  </>
                    : <>
                      <p>{t("Next payment")}:</p>
                      <h5>
                        {!subscription.subscriptionCancelOnPeriodEnd
                          ? new Date(subscription.current_period_end).toLocaleString('el-GR')
                          : t("Next scheduled payment canceled")}
                      </h5>
                    </>
                    }
                    {subscription.status === 'active' && subscription?.numberOfdays ?  
                  <>
                  <p>{t("Total running time")}:</p>
                  <h5>
                    {new Date(subscription.createdAt).toLocaleString('el-GR')} - today
                  </h5>
                </>
                    : null
                    }
                    {subscription.amountTopUp ? (
                      <>
                        <p>{t("Next invoice is set to")}:</p>
                        <h5>€ {subscription.amountTopUp / 100},00</h5>
                      </>
                    ) : null}
                  </SubscriptionCardGrid>

              </SubscriberCardInner>

                {/* {hasPermission?  */}
                <SubscriberFixActions>
                    <Grey_Link noPadding iconLeft='Settings' text={t('Fix quest')} onClick={()=> {setFixQuest(true)
                      setQuestToFix(subscription)
                    }}/>
                  </SubscriberFixActions>
                {/* : null} */}
          </SubscriberCard>
          </>
        )})}
        </SubscriberCardWrapper>
      </PageAlwaysVisible>
      <PageAlwaysVisible>
      <PageSubtitle>
      <h3>{t('Kpis')}</h3>
      </PageSubtitle>
      <CardsForty>
         <Graph_Box
            kpiTitle={'Total revenue'}
            kpiValue={'€'+ (calculateSum(mergedQuests, 'amountPaid') ? parseFloat(calculateSum(mergedQuests, 'amountPaid'))?.toFixed(2) : 'Loading...')}
            unclickable
          />

          <Graph_Box
            kpiTitle={'Max Adspend'}
            kpiValue={'€'+ (calculateSum(mergedQuests, 'expectedAdSpend') ? parseFloat(calculateSum(mergedQuests, 'expectedAdSpend'))?.toFixed(2) : 'Loading...')}
            unclickable
          />

          <Graph_Box
            kpiTitle={'Total Commission'}
            kpiValue={'€'+ (calculateSum(mergedQuests, 'fee') ? parseFloat(calculateSum(mergedQuests, 'fee'))?.toFixed(2) : 'Loading...')}
            unclickable
          />
          <Graph_Box
            kpiTitle={'Total actual Adspend'}
            kpiValue={'€'+ (calculateSum(questAdSpends, 'metaAdspend') ? parseFloat(calculateSum(questAdSpends, 'metaAdspend'))?.toFixed(2) : 'Loading...')}
            unclickable
            
          /> 
          <Graph_Box
            kpiTitle={'Total difference Questad-Actual'}
            kpiValue={`€${
              calculateSum(mergedQuests, 'expectedAdSpend') - calculateSum(questAdSpends, 'metaAdspend') 
                ? (calculateSum(mergedQuests, 'expectedAdSpend') - calculateSum(questAdSpends, 'metaAdspend')).toFixed(2)
                : "Loading..."
            }`}
            unclickable
          /> 
          <Graph_Box 
            kpiTitle={'Upcoming revenue'}
            kpiValue={'€'+ (calculateSum(questAdSpends, 'upcoming') ? parseFloat(calculateSum(questAdSpends, 'upcoming'))?.toFixed(2) : 'Loading...')}
            unclickable
          />
          <Graph_Box 
            kpiTitle={`Period without quests`}
            kpiValue={calculateMonths(mergedQuests, 'uncovered')?.count?.toFixed(2) }
            kpiDesc={calculateMonths(mergedQuests, 'uncovered')?.type }
            unclickable
          />
          <Graph_Box 
            kpiTitle={'Total quests created'}
            kpiValue={[...new Set(mergedQuests?.map(q => q.questId))]?.length}
            unclickable
          />
          {/* <Graph_Box
            kpiTitle={'Average months stopped'}
            kpiValue={calculateAverageMonthsStopped(quests)}
          /> */}

           {/* <Graph_Box
            kpiTitle={'Average customer retain'} //ti einai ayto 
            kpiValue={2.5}
          /> */}
          <Graph_Box   
            kpiTitle={'Longest quest strike'}
            kpiValue={calculateLongestStreak(mergedQuests)?.toFixed(2) }
            kpiDesc={'Months'}
            // kpiValue={"Coming soon"}
            unclickable
          />

          {/* <Graph_Box   
            kpiTitle={'Average Recurring Revenue'}
            kpiValue={'€'+calculateARR(quests)/100}
          /> */}
        </CardsForty>
      </PageAlwaysVisible>
     
    </PageSixty>
    </DailyprofitSubscriber>
  )
}
