import { get_quest_info } from "../../../services/api/Mocks";
import { get_meta_adspend, get_meta_adspend_from_questad } from "../../../services/api/Subscriptions";
import { parseISO, differenceInMonths, addMonths, format, compareAsc, differenceInDays } from "date-fns";

//////////////// STEP 1 - Revenue KPIs ////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
// Input: Subscriptions from the database, kpis (invoices from the database)
// 
// Fills the subscriptions with extra info (invoices) so we can display the revenue KPIs
// Relationship: 1 sub -> N invoices.
// Note: The duplicate checks are probably useless

const matchRevenuesToSubs = (subs, kpis) => {

  let newQuests = JSON.parse(JSON.stringify(subs));
  let newQuestss = [];

  newQuests.forEach((item1, i) => {

    let newQuestDoc = {};
    const match = kpis?.kpis?.filter((item2) => ((item2._id === item1._id)));

    if (match?.length) {
  
        let totalRevenue = 0;
        let totalExpectedAdspend = 0;
        let totalFee = 0;
        let lastFound = {};
  
        match.forEach(m => {
            totalRevenue = totalRevenue + m?.amountPaid;
            totalExpectedAdspend = totalExpectedAdspend + m?.expectedAdSpend;
            totalFee = totalFee + m?.fee;
            lastFound = m;
        });
  
        lastFound.amountPaid = totalRevenue;
        lastFound.expectedAdSpend = totalExpectedAdspend;
        lastFound.fee = totalFee;
  
        const { current_period_end, ...restMatch } = lastFound;
        newQuestDoc = {...item1, ...restMatch};
        console.log(newQuestDoc)
        newQuestss.push(newQuestDoc);
      }
  })
  
  return newQuestss;
}


//////////////// STEP 2 - Meta Adspend ////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
// Input: Subscriptions enriched with revenue kpis
// 
// Fills the already enriched subscriptions with more kpis (meta ad spend)
// Notice that the date interval is fixed for the time being
// This function covers 90% of cases (where 1 subscription -> 1 quest)
const fetchAndSetQuestAdSpends = async (quests, mergedQuests, setQuestAdSpends, setMergedQuests, dateFilters) => {

    if (!quests?.length) return;

    const today = new Date(Date.now());

    let formattedDateStart;
    let formattedDateEnd;
  
    let results = [];

    console.error('NEW QUESTS HERE', mergedQuests)
    
    for await (let sub of mergedQuests) {

      let metaAdspend,questInfo ;

        formattedDateStart = new Date(today);
        formattedDateStart.setFullYear(today.getFullYear() - 2);
        formattedDateStart = formattedDateStart.toISOString().split('T')[0];
    
        // No need to fetch data past the cancelled date
        if (sub?.status !== 'active') {
          formattedDateStart = sub?.createdAt.split('T')[0];
          formattedDateEnd = sub?.current_period_end.split('T')[0];
        }
        else {
          formattedDateStart = sub?.createdAt.split('T')[0];
          formattedDateEnd = today.toISOString().split('T')[0];
        }

        // If time ranges are specified from filters
        if (dateFilters?.startDate && dateFilters?.endDate) {
          formattedDateStart = dateFilters?.startDate?.toISOString().split('T')[0];
          formattedDateEnd = dateFilters?.endDate?.toISOString().split('T')[0];
        }
    

        metaAdspend = await get_meta_adspend_from_questad([sub?.questId], {since: formattedDateStart, until: formattedDateEnd}, true);
     
        questInfo = await get_quest_info([sub?.questId], [sub?.cusId]);

        const newBudget = questInfo?.data?.data?.newBudget;

        // Update the subscription object with the newBudget if it exists
        if (newBudget) {
            sub.newBudget = newBudget;
        }
        console.log('questINFOOOOOOOOOOOOOOOOO',  questInfo);
        
        results.push({amount: questInfo?.data?.data?.amount, subId: sub.subId, metaAdspend, questId: sub?.questId, newBudget: questInfo?.data?.data?.newBudget, campaigns: questInfo?.data?.data?.campaigns });
      }

      console.error('AAAAAAAAAA', mergedQuests)
      console.error('result', results)

      let f = finalMergeOfSubsIntoQuests(mergedQuests, results);

      console.log(f.quests, f.adspends)

      // Update mergedQuests in state
      setMergedQuests(f.quests);
      setQuestAdSpends(f.adspends);
  };


  //////////////// STEP 3 - Error correction ///////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // 
  // The problem: Normally the relationship is 1 subscription -> 1 quest, but in some cases,
  // if a user restarted a quest N times, we end up with N subscriptions sharing the same questId,
  // which causes the frontend to display duplicate quest tabs, resulting in inaccurate KPIs.

  const finalMergeOfSubsIntoQuests = (subs, adspends) => {

    let quests = [];
    let newAspends = [];
    let usedQuestIds = [];

    let subss = JSON.parse(JSON.stringify(subs));
    let adspendss = JSON.parse(JSON.stringify(adspends));


    subss.forEach(sub => {

      if (!usedQuestIds?.includes(sub?.questId)) {

        let subsWithSameQuestId = subss.filter((item) => ((item.questId === sub?.questId)));
        let adspendsWithSameQuestId = adspendss.filter((item) => ((item.questId === sub?.questId)));
        
        let quest = JSON.parse(JSON.stringify(sub));
        let adspend = JSON.parse(JSON.stringify(adspendsWithSameQuestId[0]));

        quest.amountPaid = sum(subsWithSameQuestId, 'amountPaid');
        quest.expectedAdSpend = sum(subsWithSameQuestId, 'expectedAdSpend');
        quest.fee = sum(subsWithSameQuestId, 'fee');
        quest.cancel_at_period_end = max(subsWithSameQuestId, 'cancel_at_period_end', 'cancel_at_period_end');
        quest.createdAt = max(subsWithSameQuestId, 'Date', 'createdAt')?.toISOString();
        quest.current_period_start = min(subsWithSameQuestId, 'Date', 'current_period_start')?.toISOString();
        quest.current_period_end = max(subsWithSameQuestId, 'Date', 'current_period_end')?.toISOString();
        quest.createdAt = min(subsWithSameQuestId, 'Date', 'createdAt')?.toISOString();
        quest.status = max(subsWithSameQuestId, 'status', 'status');
        // quest.campaigns = max(adspendsWithSameQuestId, 'campaigns', 'campaigns');
        quest.campaigns = adspend?.campaigns;
        adspend.metaAdspend = sum(adspendsWithSameQuestId, 'metaAdspend');
        adspend.newBudget = max(adspendsWithSameQuestId, 'newBudget', 'newBudget');

        if (sub?.newBudget) {
          if (sub?.status == "active") {
            adspend.upcoming = sum(adspendsWithSameQuestId, 'newBudget')/sub?.tax/100;
          }
          else {
            adspend.upcoming = 0;
          }
        }
        else {
          if (sub?.status == "active") {
            adspend.upcoming = sum(adspendsWithSameQuestId, 'amount')/sub?.tax/100;
          }
          else {
            adspend.upcoming = 0;
          }
        }

        usedQuestIds.push(sub?.questId);
        quests.push(quest);
        newAspends.push(adspend);
      }
    })

    return {quests, adspends: newAspends}
  }

  const max = (dataArray, dataType, property) => {

    dataArray = dataArray.map(item => item[property]);

    if (!Array.isArray(dataArray) || dataArray.length === 0) {
      return null; 
    }
    if (dataType === 'Number') {
      return Math.max(...dataArray);
    } else if (dataType === 'Date') {
      return new Date(Math.max(...dataArray.map(date => new Date(date))));
    } else if (dataType === 'status') {
      if (dataArray?.includes("active")) {
        return "active"
      }
      else {
        return dataArray[dataArray?.length-1];
      }
    } else if (dataType === 'cancel_at_period_end' || dataType == 'newBudget') {

      const firstTruthy = dataArray.find(element => Boolean(element));

      return firstTruthy || false;
    }
  };
  
  const min = (dataArray, dataType, property) => {

    dataArray = dataArray.map(item => item[property]);

    if (!Array.isArray(dataArray) || dataArray.length === 0) {
      return null; 
    }
    if (dataType === 'Number') {
      return Math.min(...dataArray);
    } else if (dataType === 'Date') {
      return new Date(Math.min(...dataArray.map(date => new Date(date))));
    }
  };

  const diff = (dataArray, property) => {

    dataArray = dataArray.map(item => item[property]);
  
    const maxValue = Math.max(...dataArray);

    // Subtract all values except the max
    const result = dataArray.reduce((acc, value) => {
      return value === maxValue ? acc : acc - value;
    }, maxValue); // Initialize with max value

    return result;
  }

  const sum = (dataArray, property) => {

    dataArray = dataArray.map(item => item[property]);

    let sum = 0;

    dataArray?.forEach(d => {
      sum = sum + (d ? d : 0);
    })

    return sum;
  }

  // End of all steps
  /////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////
  // Start of helpers

  const calculateLongestStreak = (quests) => {
    if (!quests || quests.length === 0) {
      return 0; // Return 0 if no quests are provided
    }
  
    // Parse all quest periods and sort by start date
    const periods = quests
      .map((quest) => ({
        start: parseISO(quest?.createdAt),
        end: parseISO(quest?.current_period_end),
      }))
      .sort((a, b) => a.start - b.start);
  
    let longestStreak = 0;
    let currentStreak = 0;
    let previousEnd = null;
  
    periods.forEach(({ start, end }) => {
      if (previousEnd && differenceInDays(start, previousEnd) === 1) {
        // If the current subscription starts the day after the previous ends, continue the streak
        currentStreak += differenceInDays(end, start) + 1; // Include current subscription days
      } else if (!previousEnd || start > previousEnd) {
        // If there’s a gap or it's the first subscription, reset the streak
        longestStreak = Math.max(longestStreak, currentStreak);
        currentStreak = differenceInDays(end, start) + 1; // Start a new streak
      }
      // Update previousEnd to the current subscription's end
      if (!previousEnd || end > previousEnd) {
        previousEnd = end;
      }
    });
  
    // Final check to account for the last streak
    longestStreak = Math.max(longestStreak, currentStreak);

    console.log('longestStreak', longestStreak);
    
  
    return Math.ceil(longestStreak / 30); // Convert days to months
  };
  
  // const calculateAverageMonthsStopped = (quests) => {
  //   if (!quests || quests.length <= 1) {
  //     return 0; // No gaps if there are 0 or 1 quests
  //   }
  
  //   // Sort quests by their start date
  //   const sortedQuests = quests.sort((a, b) =>
  //     compareAsc(parseISO(a.current_period_start), parseISO(b.current_period_start))
  //   );
  
  //   // Calculate gaps between consecutive quests
  //   const gaps = [];
  //   for (let i = 1; i < sortedQuests.length; i++) {
  //     const prevEnd = parseISO(sortedQuests[i - 1]?.current_period_end);
  //     const currentStart = parseISO(sortedQuests[i]?.current_period_start);
  
  //     const gapInMonths = differenceInMonths(currentStart, prevEnd);
  //     if (gapInMonths > 0) {
  //       gaps.push(gapInMonths); // Only consider positive gaps
  //     }
  //   }
  
  //   // Calculate average
  //   const totalGaps = gaps?.reduce((sum, gap) => sum + gap, 0);
  //   const averageGap = totalGaps / gaps?.length;
  

  //   console.log('totalGaps', totalGaps);
    
  //   return gaps?.length > 0 ? averageGap : 0; // Return average or 0 if no gaps
  // };

  const calculateMonths = (quests, type) => {
    if (!quests || quests.length === 0) {
      return 0; // Return 0 if no quests are provided
    }
  
    // Parse all quest periods
    const periods = quests?.map((quest) => ({
      start: parseISO(quest?.createdAt),
      end: parseISO(quest?.current_period_end),
    }));
  
    // Determine the full range of days
    const earliestStart = periods?.reduce((min, p) => (p.start < min ? p.start : min), periods[0]?.start);
    const latestEnd = periods?.reduce((max, p) => (p.end > max ? p.end : max), periods[0]?.end);
  
    // Sort periods by start date
    periods.sort((a, b) => a.start - b.start);
  
    // Calculate uncovered days
    let uncoveredDays = 0;
    let previousEnd = earliestStart;
  
    periods.forEach(({ start, end }) => {
      if (start > previousEnd) {
        // Add the gap between subscriptions
        uncoveredDays += differenceInDays(start, previousEnd);
      }
      // Update the previous end to the current subscription's end if it's later
      if (end > previousEnd) {
        previousEnd = end;
      }
    });
  
    // Add the days after the last subscription period if needed
    if (previousEnd < latestEnd) {
      uncoveredDays += differenceInDays(latestEnd, previousEnd);
    }
  
    
    const uncoveredMonths = uncoveredDays / 30;
  
    // Calculate covered days
    const totalDays = differenceInDays(latestEnd, earliestStart);
    const coveredDays = totalDays - uncoveredDays;
    const coveredMonths = coveredDays / 30;
  
    // Debugging information
    console.log('Total Days:', totalDays);
    console.log('Uncovered Days:', uncoveredDays);
    console.log('Covered Days:', coveredDays);
    console.log('Uncovered Months:', uncoveredMonths);
    console.log('Covered Months:', coveredMonths);
  
    // Return based on type
    if (type === 'uncovered') {
      if (uncoveredDays<30)return {type:'Days', count:uncoveredDays};
      else return {type:'Months', count:uncoveredMonths};
    }
    if (type === 'covered') {
      if (coveredDays<30)return {type:'Days', count:coveredDays};
      else return {type:'Months', count:coveredMonths};
    }
  
    return 0; // Default return in case of an invalid type
  };

  const calculateDifferenceSum = (subscribers) => {

    let sum = 0;

    subscribers?.forEach(s => {
      sum = sum + (s?.totalExpectedAdSpend ? s?.totalExpectedAdSpend : 0) - (s?.metaAdspent ? s?.metaAdspent : 0);
    })

    return sum.toFixed(2);
  }

  const calculateSum = (subscribers, property) => {

    let sum = 0;

    subscribers?.forEach(s => {
      if (s[property]) sum = sum + (s[property] ? s[property]: 0);
    })

    return sum.toFixed(2);
  }

  function adjustEndDate(startDate, endDate) {
    // Convert dates to Date objects if they are not already
    startDate = new Date(startDate);
    endDate = new Date(endDate);
  
    // Calculate the difference in days
    const timeDiff = endDate.getTime() - startDate.getTime(); // Difference in milliseconds
    const dayDiff = timeDiff / (1000 * 60 * 60 * 24); // Convert to days

    console.log(dayDiff)
  
    // Check if the difference is between 31 and 40 days
    if (dayDiff >= 31 && dayDiff <= 40) {
      // Set endDate to startDate + 29 days
      endDate.setDate(startDate.getDate() + 29);
    }
  
    return endDate;
  }
  

  export {
      fetchAndSetQuestAdSpends,
      matchRevenuesToSubs,
      calculateLongestStreak,
      calculateMonths,
      calculateDifferenceSum,
      calculateSum
    }