import { Delete_Plain, device, Dropdown_, IconSvg, Input_, Main_, Slider_ } from 'monica-alexandria'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { repairQuest, repairMetaAdspend } from '../../../../services/api/Mocks';

const InputCampaignWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr auto;
  align-items: center;
  grid-column-gap: 2rem;

  @media ${device.lg} {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }

  @media ${device.md} {
    grid-template-columns: auto 1fr;
    grid-row-gap: 0rem;
  }

  @media ${device.sm} {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
`;

export const FormList = styled.ul`
  margin-top: 2rem;

  li {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    grid-gap: 1rem;
    padding: 0.5rem 1.6rem;

    svg {
      width: var(--smallIcon);
      fill: ${(p) => p.theme.color};
    }
  }
`

export default function Dailyprofit_Subscriber_Fix_Quest(props) {
    const {t} = useTranslation();
    const [selected, setSelected] = useState({type:'', campaign_id:''})
    const [campaignList, setCampaignList] = useState([])

    const campaignOptions = [
        { value: 'INSTANT_FORM', label: (t('Instant form')) },
        { value: 'LEADS', label: (t('Leads')) },
        { value: 'SALES', label: (t('Sales')) },
        { value: 'TRAFFIC', label: (t('Traffic')) },
        { value: 'SALES_RETARGETING', label: (t('Sales retargeting')) },
        { value: 'RESTAURANTS_RETARGETING', label: (t('Restaurants retargeting')) },
        { value: 'RESTAURANTS_ENGAGEMENT', label: (t('Restaurants engagement')) },
      
    ];

    const handleAddToList = () => {
        if (selected.type && selected.campaign_id) {
          setCampaignList((prevList) => [...prevList, selected]);
          setSelected({ type: '', campaign_id: '' }); // Clear the selected fields after adding
        } else {
          alert(t('Please fill in both type and campaign before adding.'));
        }
      };
      
      const handleRemoveFromList = (index) => {
        setCampaignList((prevList) => prevList.filter((_, i) => i !== index));
      };
      

      console.log('selected', selected, campaignList);
      
      const handleType = (value)=>{
        // const selectedOption = campaignOptions?.find((option) => option.value === value);
        setSelected({...selected, type: value})
      }

      const repair_quest = ()=>{
        const data={
          questId:  props.selectedQuest?.questId,
          campaigns: campaignList || [],
          start: props?.selectedQuest?.current_period_start,
          end: props?.selectedQuest?.current_period_end
        }
        repairQuest(data)
        .then(res => {

          if(res.data?.code === "AD2000"){

            
            repairMetaAdspend([props.selectedQuest?.questId])
            .then(res =>
              {
                console.log("res data repair ", res?.data)
                const updatedCampaigns = [
                  ...(props.selectedQuest?.campaigns || []),
                  ...campaignList,
                ];
        
                // Assuming `setSelectedQuest` is available to update the parent state
                props.setSelectedQuest((prevQuest) => ({
                  ...prevQuest,
                  campaigns: updatedCampaigns,
                }));
              }

            )

    
            props.fetchSubscriberInfo();
            // setCampaignList([])
          }
         
          
        })

           props.setFixQuest(false)
      }
      console.log('props.selectedQuest', props.selectedQuest);
      console.log('selected', selected);
      
  return (
    <Slider_
        title={t("Fix quest")}
        text = {t("Done")}
        onClose = {() => props.setFixQuest(false)}
        onClick = {() => repair_quest()}
    >
    <InputCampaignWrapper>
      <Dropdown_>
        <select
          value={selected?.type}
          // onChange={(e) => setSelected({...selected, type: e.target.value})}
          onChange={(e)=> handleType(e.target.value)}
          className="Dropdown_EmojiAdapt"
        >
          {campaignOptions?.map((campaign, i) => (
              <option key={i} value={campaign?.value}>
                {campaign?.label}
              </option>
            ))}
        </select>
      </Dropdown_>
      <Input_
        onChange={(e)=> setSelected({...selected, campaign_id: e.target.value})}
  
      />
      <Main_ iconLeft='Add' onClick={handleAddToList} />
    </InputCampaignWrapper>
    {props?.selectedQuest?.campaigns ? (
      <FormList className="FormList">
        {props?.selectedQuest?.campaigns?.map((item, i) => {
          const selectedOption = campaignOptions?.find((option) => option.value === item.type);
          return (
            <li key={i}>
              <IconSvg Icon="Bullet" />
              <div>
                <h6>
                  {selectedOption?.label} {item.campaign_id}
                </h6>
              </div>
            </li>
          );
        })}
      </FormList>
    ) : null}

    {campaignList ? (
        <FormList className="FormList">
          {campaignList?.map((item, i) => {
            const selectedOption = campaignOptions?.find((option) => option.value === item.type);
          return(
            <li key={i}>
                <IconSvg Icon={'Bullet'} />
                <div>
                    <h6>{selectedOption?.label} {item.campaign_id} </h6>
                </div>
                <Delete_Plain
                    onClick={() => handleRemoveFromList(i)}
                    size="small"
                    iconLeft="Delete"
                />
            </li>
          )})}
        </FormList>
      ) : null}
    </Slider_>
  )
}
