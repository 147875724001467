import {
  Filters_,
  FormGroup,
  FormInput,
  FormLabel,
  Grey_Link,
  IconSvg,
  Main_Plain,
  PageFull,
  PageTitle,
  TBody,
  TD,
  TH,
  THead,
  TR,
  TRH,
  Table,
  TableDesktop,
  Partial_Loader,
} from "monica-alexandria";
import { useDebounce } from 'use-debounce';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { get_subscribers } from "../../../services/api/Mocks";
import { Subscriber_Filters } from "./partials/Subscriber_Filters";
import { useNavigate } from "react-router-dom";
import { Sub_Color_Coding } from "./partials/Sub_Color_Coding";
import Subscriber_Edit from "./partials/Subscriber_Edit";
import { showKPIS } from "./utils/kpis";
import styled from "styled-components";
import { calculateDifferenceSum, calculateFeeSum, calculateSum } from "../dailyprofitSubscriber/utils";

const DashboardLeadsLoadBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const GoogleSearch = styled.div``;

const GoogleForm = styled(FormGroup)``;

const GoogleInput = styled(FormInput)`
  padding-left: 4.5rem;
`;

const GoogleLabel = styled(FormLabel)`
  padding-left: 3rem;
`;

const GoogleSearchIcon = styled.div`
  position: absolute;
  top: 1.5rem;
  left: 1rem;

  svg {
    width: 3rem;
    fill: var(--greyDark);
  }
`;

const SubscribersDifference = styled(TD)`
    color: ${p => p.negative ? 'var(--error)' : 'var(--success)'};
`

export default function SUBSCRIBERS() {
    
  
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [searchTermm, setSearchTerm] = useState("");

    const [currPage, setCurrPage] = useState(0);
    const [currPageFilters, setCurrPageFilters] = useState(0);
    const [pageSize, setPageSize] = useState(15);
    const [loading, showLoading] = useState(true);

    const [searchTerm] = useDebounce(searchTermm, 2000);
    
    const [subscribers, setSubscribers] = useState({
        subscribers_with_kpis: [],
        currently_showing: "backend",
        show_loader: false,
    });

    const [filters, setFilters] = useState({
        category: ['dailyprofit']
    });

    const [sorting, setSorting] = useState('');


    const [dates, setDates] = useState({
        renderedConversionDates: [],
        renderedLossDates: [],
        conversionDates: {
            sinceDate: "",
            untilDate: "",
        },
        lossDates: {
            sinceDate: "",
            untilDate: "",
        },
    });

    const [slider, toggleSlider] = useState(false);
    const [selectedSub, setSelectedSub] = useState(false);


    const fetchSubscribers = (payload) => {

        console.log('payload');
        
        get_subscribers(payload)
            .then((res) => {
                console.log('gettttttttttttt', res)

                if (!res?.data?.data?.subscribers?.length) {
                    showLoading(false);
                    setSubscribers({
                    ...subscribers, show_loader: true})

                }
                else {
                    showKPIS(subscribers, setSubscribers, showLoading, currPage, currPageFilters, res?.data?.data?.subscribers, filters);
                }
            })
            .catch((err) => {
                setSubscribers({
                    ...subscribers
                });
                showLoading(false);
            });
    };

    const checkDailyprofitStatus = (subscriber) => {
        const dailyprofitSubscriptions = subscriber?.filter(subscription => subscription.category === "dailyprofit");
        let dailyprofitSubscriptionStatus = dailyprofitSubscriptions[0] ?  dailyprofitSubscriptions[0]?.subsriberStatus : '';

        console.log('dailyprofitSubscriptions', dailyprofitSubscriptions);
        
        // if (dailyprofitSubscriptions?.some(subscription => subscription.status === 'active')) {
        //     return 'active';
        // } else if (dailyprofitSubscriptions?.some(subscription => subscription.status === 'past_due')) {
        //     return 'past_due';
        // } else if (dailyprofitSubscriptions?.some(subscription => subscription.status === 'canceled')) {
        //     return 'canceled';
        // }
    
        return dailyprofitSubscriptionStatus; // If no "dailyprofit" subscription matches any of these statuses
    };

    const getMostRecentPeriodStart = (subscriber) => {
        if (!subscriber?.data || subscriber.data.length === 0) return 'N/A';
    
        const mostRecent = subscriber.data.reduce((latest, item) => {
            const currentStartDate = new Date(item.current_period_start);
            const latestDate = new Date(latest.current_period_start);
    
            return currentStartDate > latestDate ? item : latest;
        }, subscriber.data[0]); // Start with the first item as the initial value
    
        return new Date(mostRecent.current_period_start).toLocaleDateString('el-GR'); // Format the date
    };
    
    const fetchWithFilters = () => {
        let payload = {
            filters,
            sorting: sorting.field ? { [sorting.field]: sorting.key } : undefined,
            dateStart: dates.conversionDates.sinceDate || dates.lossDates.sinceDate,
            dateEnd: dates.conversionDates.untilDate || dates.lossDates.untilDate,
            elastic: searchTerm, // Include search term in payload
            pageSize: pageSize,
            currentPage: currPage
        };
        fetchSubscribers(payload);
    };

    useEffect(() => {
        showLoading(true)
        fetchWithFilters();
    }, [filters, dates, sorting, searchTerm, currPage]); // Update on searchTerm change

    useEffect(() => {
        if (filters?.category?.length) {
            showLoading(true);
            setCurrPage(0);
        }
        else {
            setCurrPageFilters(0);
        }
    }, [filters?.category]); // Page number must return back to normal if we exit filter mode

    const handleSearchInput = (e) => {
        setSearchTerm(e.target.value);
    };

    console.error('FILTERS', filters)
    console.error('subs', subscribers)
    console.error('currPage', currPage)
    console.error('dates', dates)


  return (
    <PageFull>
        {slider ? 
            <Subscriber_Filters
                toggleSlider={toggleSlider} 
                filters={filters}
                setFilters={setFilters}
                dates={dates}
                setDates={setDates}
                sorting={sorting}
                setSorting={setSorting}
            /> 
        : null}
        {selectedSub  ?
            <Subscriber_Edit 
                selectedSub={selectedSub}
                setSelectedSub={setSelectedSub}
            />
        : null}
      <PageTitle>
        <Grey_Link noPadding iconLeft="Back" onClick={() => navigate(-1)} />
        <h1>{t("Subscribers")}</h1>
      </PageTitle>
      {!loading ? <Filters_ 
        filters={t("Filters")} 
        onFilters = {() => toggleSlider(true)}
        search={
            <GoogleSearch>
            <GoogleForm>
              <GoogleInput
                placeholder={t('Search subscriber')}
                value={searchTermm}
                onChange={handleSearchInput}
                //onChange={triggerDebounce}
                // defaultValue={currentSearch.defaultValue}
                // disabled={props.disabled}
              />
              <GoogleLabel>{t('Search subscriber')}</GoogleLabel>
              <GoogleSearchIcon>
                <IconSvg Icon="Search" />
              </GoogleSearchIcon>
            </GoogleForm>
          </GoogleSearch> 
        }
      />: <></>}
      <TableDesktop>
      {loading ? <Partial_Loader text={'Loading subs'} /> : null}
        <Table>
            <THead>
                <TRH>
                    <TH>{filters?.startDate && filters?.endDate ? `Dates: ${filters?.startDate?.toLocaleDateString('el')} - ${filters?.endDate?.toLocaleDateString('el')}` : 'Dates: all-time' }</TH>
                    <TH>#</TH>
                    <TH>{t("Last payment")}</TH>
                    <TH>{t("Subscriptions")}</TH>
                    {/* <TH>{t("Subscriptions Total")}</TH> */}
                    <TH>{t("Username")}</TH>
                    <TH>{t("First name")}</TH>
                    <TH>{t("Last name")}</TH>
                    {/* <TH>{t("Questad Total Revenue")}</TH>
                    <TH>{t("Total Fee")}</TH>
                    <TH>{t("Expected AdSpend")}</TH>
                    <TH>{t("Meta Adspend")}</TH> */}
                    <TH>{t("Total Difference Questad - Actual")}</TH>
                </TRH>
            </THead>
            <TBody>
                {subscribers?.subscribers_with_kpis?.map((subscriber, i)=>
                    <TR>
                        <TD><Main_Plain iconLeft='EyeOpen' onClick={()=>navigate(`${subscriber?.data[0]?.userId}`)} /></TD>
                        {/* <TD><Main_Plain iconLeft='EyeOpen' onClick={()=>setSelectedSub(subscriber)} /></TD> */}
                        <TD>{i + 1}</TD>
                        <TD>{getMostRecentPeriodStart(subscriber)}</TD>
                        {/* <TD>{subscriber?.data[0]?.registered ? new Date(subscriber?.data[0]?.registered).toLocaleDateString('el-GR') : <></>}</TD> */}
                        <TD>
                            <Sub_Color_Coding 
                                lois={subscriber?.data?.some(subscription => subscription.category === "lois")}
                                loisStatus = {subscriber?.data?.find(subscription => subscription.category === "lois")?.status}

                                dailyprofit={subscriber?.data?.some(subscription => subscription.category === "dailyprofit")}
                                dailyprofitStatus = {checkDailyprofitStatus(subscriber?.data)}
                                
                                bond={subscriber?.data?.some(subscription => subscription.category === "bond")} 
                                bondStatus={subscriber?.data?.find(subscription => subscription.category === "bond")?.status}
                            />
                        </TD>
                        {/* <TD>{subscriber?.totalUserRevenue/ 100}</TD> */}
                        <TD>{subscriber?.data[0]?.username}</TD>
                        <TD>{subscriber?.data[0]?.firstname}</TD>
                        <TD>{subscriber?.data[0]?.lastname}</TD>
                        {/* <TD>{subscriber?.totalRevenue?.toFixed(2)}</TD>
                        <TD>{subscriber?.totalFee?.toFixed(2)}</TD>
                        <TD>{subscriber?.totalExpectedAdSpend?.toFixed(2)}</TD>
                        <TD>{subscriber?.metaAdspent?.toFixed(2)}</TD> */}
                        <SubscribersDifference negative={(subscriber?.totalExpectedAdSpend-subscriber?.metaAdspent)<0}>
                            { ((subscriber?.totalExpectedAdSpend ? subscriber?.totalExpectedAdSpend : 0) - (subscriber?.metaAdspent ? subscriber?.metaAdspent : 0))?.toFixed(2)}
                        </SubscribersDifference>
                    </TR>
                )}
                <TR>
                    <TD><b>SUM</b></TD> 
                    <TD></TD> 
                    <TD></TD> 
                    <TD></TD> 
                    {/* <TD><b>{calculateSum(subscribers?.subscribers_with_kpis, 'totalUserRevenue') / 100}</b></TD>  */}
                    <TD></TD> 
                    <TD></TD> 
                    <TD></TD> 
                    {/* <TD><b>{calculateSum(subscribers?.subscribers_with_kpis, 'totalRevenue')}</b></TD> 
                    <TD><b>{calculateSum(subscribers?.subscribers_with_kpis, 'totalFee')}</b></TD> 
                    <TD><b>{calculateSum(subscribers?.subscribers_with_kpis, 'totalExpectedAdSpend')}</b></TD> 
                    <TD><b>{calculateSum(subscribers?.subscribers_with_kpis, 'metaAdspent')}</b></TD>  */}
                    <TD><b>{calculateDifferenceSum(subscribers?.subscribers_with_kpis)}</b></TD> 
                </TR>
            </TBody>
        </Table>
        
        {!subscribers?.show_loader && subscribers?.subscribers_with_kpis?.length ? 
        <DashboardLeadsLoadBtn>
                <Main_Plain text={t('Load more')} iconLeft='Loading' onClick={()=> {
                    showLoading(true);
                    if (slider) {
                        setCurrPageFilters(currPageFilters+1);
                    }
                    else {
                        setCurrPage(currPage+1);
                    }
                }} />
            </DashboardLeadsLoadBtn> : <></>}
      </TableDesktop>
    </PageFull>
  );
}
